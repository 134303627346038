import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import {
  ProfileType,
  UserActionTypes,
  UserSessionEvent,
} from "../types/userTypes";
import { removeJWT, getJWT } from "../utils/auth";
import API from "../api/api";

/**
 * Custom hook that provides the status and status management of the Logged User
 */
export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext);
  const [mounted, setMounted] = useState<boolean>(false);

  const resetUserState = useCallback(async () => {
    removeJWT();
    dispatch({
      type: UserActionTypes.RESET_STATE,
    });
  }, [dispatch]);

  const setIsLogged = useCallback(
    (isLogged: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_LOGGED,
        payload: isLogged,
      });
    },
    [dispatch]
  );

  const setProfile = useCallback(
    (profile: ProfileType) => {
      dispatch({
        type: UserActionTypes.SET_PROFILE,
        payload: profile,
      });
    },
    [dispatch]
  );

  const setIsSessionExpired = useCallback(
    (isSessionExpired: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_SESSION_EXPIRED,
        payload: isSessionExpired,
      });
    },
    [dispatch]
  );

  const fetchUserData = useCallback(async () => {
    const jwt = getJWT();
    if (!jwt || state.profile) {
      return;
    }
    try {
      const response = await API.get("auth/me");
      if (response?.data) {
        setProfile({
          id: response?.data.id,
          rut: response?.data.rut,
          name: response?.data.name,
          lastName: response?.data.lastName,
          phone: response?.data.phone,
          email: response?.data.email,
          createdAt: response?.data.createdAt,
          updatedAt: response?.data.updatedAt,
        });
        setIsLogged(true);
      } else {
        throw new Error("404");
      }
    } catch (error) {
      setIsLogged(false);
      resetUserState();
    }
  }, [resetUserState, setIsLogged, setProfile, state.profile]);

  // Handling session expiration
  useEffect(() => {
    const notifySessionExpiration = () => {
      setIsSessionExpired(true);
    };
    window.addEventListener(UserSessionEvent.EXPIRED, notifySessionExpiration);
    return () => {
      window.removeEventListener(
        UserSessionEvent.EXPIRED,
        notifySessionExpiration
      );
    };
  }, [setIsSessionExpired]);

  useEffect(() => {
    if (!mounted) {
      fetchUserData();
      setMounted(true);
    }
  }, [mounted, fetchUserData]);

  return {
    resetUserState,
    isLogged: state.isLogged,
    isSessionExpired: state.isSessionExpired,
    profile: state.profile,
    fetchUserData,
    setProfile,
    setIsLogged,
    setIsSessionExpired,
  };
};
