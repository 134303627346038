export enum DebtSupplierCoreStatus {
  Pending = "pending",
  InProcess = "in_process",
  Payed = "payed",
}

export interface DebtSupplierCoreAmount {
  value: number;
  currency: string;
}

export interface DebtSupplierCoreItem {
  id: unknown;
  type: string;
  details: string;
  installment: number;
  installmentDueDate: Date | null;
  amount: DebtSupplierCoreAmount; // Amount without interests
  interests: DebtSupplierCoreAmount;
  finalAmount: DebtSupplierCoreAmount; // Amount with interests
  status: DebtSupplierCoreStatus;
  selected?: boolean;
  partial?: boolean;
}

export interface DebtSupplierCoreCustomer {
  name: string;
  customerIdentifier: string;
}

export type RecentAccountType = {
  rut: string;
  name: string;
};

export interface DebtResponse {
  customer: DebtSupplierCoreCustomer;
  debts: DebtSupplierCoreItem[];
}

export interface ErpDebtKey {
  TIPO_DOCUMENTO: string;
  CODIGO_CLI: string;
  EMPRESA: string;
  CORRELATIVO: number;
  CUOTA: number;
  ORIGEN: string;
  CORRELATIVO_CAJA: number;
}

export interface SoftlandDebtKey {
  IDENTIFICADOR_DEUDA: string;
}
