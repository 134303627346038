import { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { Button } from "@octano/global-ui";

import CustomButton from "../../../components/button/CustomButton";
import { useUserState } from "../../../hooks";
import { t } from "../../../utils/utils";
import useStepStore from "../store/step/stepStore";
import { requestGetPaymentResult } from "./api";
import BaseSuccess from "./parts/Base/SuccessComponent";
import BaseError from "./parts/Base/ErrorComponent";

import TransbankWPP from "./parts/TransbankWPP";
import TransbankOneclick from "./parts/TransbankOneclick";

enum PaymentResultCode {
  NotFound = "404",
  TransbankWPP = "payment-supplier.transbank-wpp",
  TransbankOneclick = "payment-supplier.transbank-oneclick",
}

/**
 * Resolver los re-renderizados que se producen en esta vista. Puede que tenga relación con el index de la raiz de paymentFlow
 * revisar el montaje de los componentes a partir del paso.
 */

//https://www.transbankdevelopers.cl/referencia/webpay#confirmar-una-transaccion
export interface PaymentResult<T = any> {
  code: PaymentResultCode;
  status: boolean;
  data: T;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type ComponentsObject = { [key: string]: (p?: any) => JSX.Element };

const SuccessComponents: ComponentsObject = {
  [PaymentResultCode.TransbankWPP]: (props?: any) => (
    <TransbankWPP.SuccessComponent {...props} />
  ),
  [PaymentResultCode.TransbankOneclick]: (props?: any) => (
    <TransbankOneclick.SuccessComponent {...props} />
  ),
};
const ErrorComponents: ComponentsObject = {
  [PaymentResultCode.TransbankWPP]: (props?: any) => (
    <TransbankWPP.ErrorComponent {...props} />
  ),
  [PaymentResultCode.TransbankOneclick]: (props?: any) => (
    <TransbankOneclick.ErrorComponent {...props} />
  ),
};

const VoucherStep = () => {
  const { isLogged } = useUserState();
  const history = useHistory();
  const resetStep = useStepStore((state) => state.resetStep);
  const setCurrentstep = useStepStore((state) => state.setCurrentStep);
  const query = useQuery();

  const transactionId = query.get("transactionId");
  const transactionError = query.get("transactionError");

  const [pdfUrl, setPdfUrl] = useState<string>();
  const [transactionData, setTransactionData] = useState<PaymentResult>();

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const [fetchingError, setFetchingError] = useState<boolean>(false);

  const openInNewTab = useCallback(() => {
    window.open(pdfUrl, "_blank", "noreferrer");
  }, [pdfUrl]);

  const handleReset = () => {
    if (isLogged) {
      history.replace("/user-dashboard/index");
      setCurrentstep(1);
    } else {
      history.replace("/dashboard/index");
      resetStep();
    }
  };

  const fetchData = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setFetchingError(false);
    setTransactionData(undefined);
    setPdfUrl(undefined);

    try {
      const { data } = await requestGetPaymentResult(
        String(transactionId),
        isLogged
      );
      setTransactionData(data);
      setPdfUrl(data?.data?.pdf);
    } catch (error) {
      setFetchingError(true);
    }

    setLoading(false);
  }, [transactionId, isLogged, loading]);

  useEffect(() => {
    if (transactionId && !mounted) {
      fetchData();
      setMounted(true);
    }
  }, [fetchData, transactionId, mounted]);

  if (loading) {
    return (
      <div className="my-5">
        <div className="mx-auto container-max-400 d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-secondary spinner-border spinner-border-lg"
            role="status"
          >
            <span className="sr-only">{t("loading")}...</span>
          </div>
        </div>
      </div>
    );
  }

  if (fetchingError || !transactionData?.code) {
    return (
      <div>
        <BaseError
          title={t("payment_fetch_error")}
          instructions={t("payment_fetch_error_retry_message")}
        />
        <div className="mx-auto container-max-400">
          <CustomButton
            type="button"
            text={t("retry")}
            colorType="secondary"
            onClick={fetchData}
          />
        </div>
      </div>
    );
  }

  if (!transactionData?.status) {
    return (
      <div>
        {ErrorComponents[transactionData.code] ? (
          ErrorComponents[transactionData.code]({
            errorInUrl: transactionError,
          })
        ) : (
          <BaseError
            title={t(
              transactionData?.code === PaymentResultCode.NotFound
                ? "payment_error_not_found"
                : "payment_error"
            )}
            instructions={t(
              transactionData?.code === PaymentResultCode.NotFound
                ? "payment_error_not_found_message"
                : "payment_error_retry_message"
            )}
          />
        )}
        <div className="mx-auto container-max-400">
          <CustomButton
            type="button"
            text={t("retry")}
            colorType="secondary"
            onClick={handleReset}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {SuccessComponents[transactionData.code] ? (
        SuccessComponents[transactionData.code](transactionData)
      ) : (
        <BaseSuccess />
      )}
      <div className="mx-auto container-max-400">
        <Row className="pb-2">
          <Col xs={12}>
            <div className="mt-4">
              <Button
                icon="download"
                text={t("button_download_voucher")}
                loading={loading}
                disabled={!pdfUrl}
                onClick={openInNewTab}
                fullwidth
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="mx-auto container-max-400">
        <Row className="pb-3">
          <Col xs={12}>
            <CustomButton
              type="button"
              text={t("go_back_to_first_step")}
              colorType="secondary"
              onClick={handleReset}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(VoucherStep);
