import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { ReactComponent as SirenIcon } from "../assets/svg/icons/siren.svg";
import { ReactComponent as LogoGlobal } from "../assets/svg/logo_global_color.svg";
import Header from "../components/menu/Header";
import LateralMenu from "../components/menu/LateralMenu";
import CustomModal from "../components/modals/CustomModal";
import LayoutRoutes from "../components/routes/LayoutRoutes";
import { Layouts } from "../config/constants";
import { useUserState } from "../hooks";
import { useQuery } from "../hooks/useQuery";
import { t } from "../utils/utils";
import { useEffect } from "react";

type DashboardLayoutProps = {
  layout: Layouts.DASHBOARD | Layouts.USER_DASHBOARD;
};

const DashboardLayout = ({ layout }: DashboardLayoutProps) => {
  const { isLogged, isSessionExpired, resetUserState, setIsSessionExpired } =
    useUserState();
  const query = useQuery();

  // Handling session expiration
  useEffect(() => {
    const notifySessionExpiration = () => {
      setIsSessionExpired(true);
    };
    window.addEventListener("user-session-expired", notifySessionExpiration);
    return () => {
      window.removeEventListener(
        "user-session-expired",
        notifySessionExpiration
      );
    };
  }, [setIsSessionExpired]);

  const logout = () => {
    resetUserState();
  };

  if (!isLogged && layout === Layouts.USER_DASHBOARD) {
    return <Redirect to={Layouts.AUTH} />;
  }

  if (isLogged && layout !== Layouts.USER_DASHBOARD) {
    // El flujo de pago cede el control a webpay para la parte de cobro. Cuando se finaliza el flujo
    // ya sea por exito o error, webpay redirecciona a la vista de pagos pasandole el parametro de codigo de error
    // o el codigo de la orden de compra. Sin embargo, la configuracion de webpay solo acepta un url estatico
    // para la redireccion y se utilizo el de usuario no loggeado.
    // Por eso en caso de que el usuario este loggeado y se ingrese al sitio mediante la redireccion de webpay
    // (detectable via los parametros del url) se redirecciona a la vista de pagos del usuario loggeado con dichos
    // parametros
    //
    // NOTA: En caso de cambiar la ruta de pagos debe cambiarse esta redireccion manualmente en el archivo

    if (query.get("transactionError") || query.get("transactionId")) {
      return (
        <Redirect to={`${Layouts.USER_DASHBOARD}/index?${query.toString()}`} />
      );
    }

    return <Redirect to={Layouts.USER_DASHBOARD} />;
  }
  return (
    <>
      <div className="d-flex layout">
        <LateralMenu layout={layout} />
        <div
          className="p-0 min-vh-100 flex-grow-1 dashboard-container"
          style={{ width: 300 }}
        >
          <Container
            className="px-3 px-md-5 overflow-scroll-hidden vh-100"
            fluid
          >
            <div className="d-flex flex-column min-vh-100 pb-3">
              <Header pathLayout={layout} />
              <div className="d-flex flex-column flex-grow-1">
                <Container fluid className="bg-white rounded-sm p-3">
                  <LayoutRoutes pathLayout={layout} defaultViewPath="/index" />
                </Container>
                <div className=" mt-auto text-right">
                  <div className={"pt-4 pb-1"}>
                    <LogoGlobal
                      style={{
                        maxWidth: "100%",
                        height: "41px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <CustomModal
        isOpen={isSessionExpired}
        icon={<SirenIcon className="svg-secondary" width="4rem" />}
        title={t("session_expired_title")}
        description={t("session_expired_description")}
        textButton={t("go_back_to_login")}
        closeModal={logout}
      />
    </>
  );
};

export default DashboardLayout;
