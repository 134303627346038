import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { UserStore } from "./type";
import {
  SecureStorageStrategy,
  UnsafeStorageStrategy,
} from "../storageStrategy";
import { DebtSupplierCoreItem } from "../../../../types/debtType";

const userStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        user: { name: "", customerIdentifier: "" },
        debts: [],
        debtsPaid: [],
        setUser: (name: string, customerIdentifier: string) =>
          set({ user: { name, customerIdentifier } }),
        addDebts: (debts: DebtSupplierCoreItem[]) => set(() => ({ debts })),
        updateDebt: (
          id: DebtSupplierCoreItem["id"],
          debt: Partial<DebtSupplierCoreItem>
        ) =>
          set((state) => ({
            debts: state.debts.map((d) =>
              d.id === id ? { ...d, ...debt } : d
            ),
          })),
        //Me permite seleccionar las deudas que marco en la tabla y calcula el total de la deuda seleccionada monto + interes
        checkDebts: (ids: unknown[]) =>
          set((state) => ({
            debts: state.debts.map((debt) => ({
              ...debt,
              selected: ids.includes(debt.id),
              finalAmount:
                ids.includes(debt.id) && !debt.selected
                  ? {
                      ...debt.finalAmount,
                      value: debt.amount.value + debt.interests.value,
                    }
                  : debt.finalAmount,
            })),
          })),
        //agrego deudas pagadas si ya existia en el array no la vuelvo a insertar
        addDebtsPaid: (debtsPaid: DebtSupplierCoreItem[]) =>
          set((state) => ({
            debtsPaid: [
              ...state.debtsPaid,
              ...debtsPaid.filter(
                (debt) =>
                  !state.debtsPaid.some(
                    (existingDebt) => existingDebt.id === debt.id
                  )
              ),
            ],
          })),
        removeDebtPaid: (debt: DebtSupplierCoreItem) =>
          set((state) => ({
            debtsPaid: state.debtsPaid.filter((d) => d !== debt),
          })),
        reset: () => {
          userStore.persist.clearStorage();
        },
      }),
      {
        name: "user-storage",
        storage: createJSONStorage(() =>
          process.env.NODE_ENV !== "production"
            ? UnsafeStorageStrategy
            : SecureStorageStrategy
        ),
      }
    )
  )
);

export default userStore;
