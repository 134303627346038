// Hooks
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

// Types
export interface PaymentSupplierCoreResponse {
  transactionIdentifier: string;
  method: "GET" | "POST" | "PUT";
  action: string;
  inputs: { name: string; value: string }[];
}

export type PaymentSupplierRedirectMethods = {
  redirect: (params?: PaymentSupplierCoreResponse) => void;
};

type PaymentSupplierRedirectProps = {};

// Render
const PaymentSupplierRedirect = (
  _props: PaymentSupplierRedirectProps,
  ref: Ref<PaymentSupplierRedirectMethods>
) => {
  const formRef: any = useRef<HTMLFormElement>(null);

  const [params, setParams] = useState<PaymentSupplierCoreResponse>();

  const handleRedirect = useCallback(
    (_params?: PaymentSupplierCoreResponse) => {
      setParams(_params);
    },
    []
  );

  useImperativeHandle(ref, () => ({
    redirect: handleRedirect,
  }));

  useEffect(() => {
    if (params?.action?.trim()) {
      formRef?.current?.submit();
    }
  }, [params?.action, formRef]);

  return (
    <form ref={formRef} method={params?.method} action={params?.action}>
      {params?.inputs?.map((e, i) => (
        <input key={i?.toString()} type="hidden" {...e} />
      ))}
    </form>
  );
};

export default forwardRef(PaymentSupplierRedirect);
