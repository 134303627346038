import PaymentSupplierItem from "./PaymentSupplierItem";

type PaymentSupplierItemProps = {
  options?: string[];
  value: string;
  disabled?: boolean;
  onChange?: (code: string) => void;
};

const PaymentSupplierItems = ({
  options = [],
  value,
  disabled,
  onChange,
}: PaymentSupplierItemProps) => {
  return (
    <div className="w-full d-flex flex-wrap" style={{ gap: 12 }}>
      {options?.map((supplier) => (
        <PaymentSupplierItem
          code={supplier}
          disabled={disabled}
          active={value === supplier}
          onPress={onChange}
        />
      ))}
    </div>
  );
};

export default PaymentSupplierItems;
