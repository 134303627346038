export default function currencyFormat(
  amount: number,
  maximumFractionDigits: number = 2
) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(amount);
}

export function currencyFormatNoSign(
  amount: number,
  maximumFractionDigits: number = 2
) {
  return new Intl.NumberFormat("es-CL", {
    style: "decimal",
    currency: "CLP",
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(amount);
}
