import { PaymentResult } from ".";
import API from "../../../api/api";
import {
  getAnonymousUserEmail,
  getApiHeaders,
  getOrderJWT,
  handleError,
} from "../../../utils/utils";

/**
 * Endpoint para obtener resultado del pago con webpay
 */

const VOUCHER_STEP = {
  PAYMENT_RESULT: (buyOrder: string) =>
    `payment-flow/voucher-step/${buyOrder}/result`,
  NOTIFY: (buyOrder: string) => `payment-flow/voucher-step/${buyOrder}/notify`,
};
const VOUCHER_STEP_AUTHENTICATED = {
  PAYMENT_RESULT: (buyOrder: string) =>
    `payment-flow/voucher-step/authenticated/${buyOrder}/result`,
  NOTIFY: (buyOrder: string) =>
    `payment-flow/voucher-step/authenticated/${buyOrder}/notify`,
};

export const requestGetPaymentResult = async (
  buyOrder: string,
  IsAuthenticated: boolean
) => {
  try {
    const url = IsAuthenticated
      ? VOUCHER_STEP_AUTHENTICATED.PAYMENT_RESULT(buyOrder)
      : VOUCHER_STEP.PAYMENT_RESULT(buyOrder);

    return API.get<PaymentResult>(url, {
      params: {
        buyOrderToken: getOrderJWT(),
        anonymousUserEmail: getAnonymousUserEmail(),
      },
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Envia el comprobante de pago adjunto en un correo
 */
export const requestSendVoucherByEmail = async (
  buyOrder: string,
  IsAuthenticated: boolean,
  email: string | null
) => {
  const headers = getApiHeaders();
  const url = IsAuthenticated
    ? VOUCHER_STEP_AUTHENTICATED.NOTIFY(buyOrder)
    : VOUCHER_STEP.NOTIFY(buyOrder);
  try {
    return await API.post<string>(
      url,
      { email, buyOrderToken: getOrderJWT() },
      { headers }
    );
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
