import { Button, TextInput } from "@octano/global-ui";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { clean, format, validate as validateRut } from "rut.js";
import CustomAlert from "../../../components/alert/CustomAlert";
import CustomButton from "../../../components/button/CustomButton";
import T from "../../../components/translate/T";
import { RECAPTCHA_SITE_KEY } from "../../../config/constants";
import { AlertErrorType, AlertType } from "../../../types/alertError";
import { t } from "../../../utils/utils";
import useStepStore from "../store/step/stepStore";
import useUserStore from "../store/user/userStore";
import { requestGetDebts } from "./api";
import { useUserState } from "../../../hooks";

type InputsSearch = {
  rut: string;
  token: string;
};

const SearchStep = () => {
  const { profile } = useUserState();
  const setCurrentStep = useStepStore((state) => state.setCurrentStep);
  const { setUser, addDebts } = useUserStore((state) => ({
    setUser: state.setUser,
    addDebts: state.addDebts,
    reset: state.reset,
  }));

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
    reset,
    setValue,
  } = useForm<InputsSearch>({ mode: "onChange" });

  const [errorDebts, setErrorDebts] = useState<AlertErrorType | null>(null);

  let recaptchaRef = useRef<ReCAPTCHA>(null);

  const searchDebts = async (values: InputsSearch) => {
    const rut = clean(values.rut);
    const recaptchaValue = values.token;
    try {
      const { data } = await requestGetDebts(rut, recaptchaValue!);
      const hasDebts = data?.debts?.length > 0;
      const hasUser = data?.customer?.customerIdentifier;

      /**
       * Si en la  request  viene un usuario con sus deudas
       */
      if (hasUser && hasDebts) {
        setUser(data.customer.name, String(data.customer.customerIdentifier));
        addDebts(data.debts);
        setCurrentStep(1);
        setErrorDebts(null);
      } else if (data?.customer === null) {
        setErrorDebts({
          type: AlertType.ERROR,
          message: t("account_not_found"),
        });
      } else {
        setErrorDebts({
          type: AlertType.INFO,
          message: t("error_without_debts"),
        });
      }
    } catch (error) {
      console.log("requestGetDebts error:", error);
      setErrorDebts({ type: AlertType.ERROR, message: t("generic_error") });
    } finally {
      if (recaptchaRef.current) {
        reset();
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <div className="mx-auto container-max-400">
      <Row className="pt-4">
        <Col xs={12}>
          <p className="text-center pt-2 fs-1-25">
            <T tKey="search_info" />
          </p>
        </Col>
      </Row>
      <Row className="px-sm-4">
        <Col xs={12} className="mb-5">
          {errorDebts && (
            <CustomAlert type={errorDebts.type} text={errorDebts.message} />
          )}
        </Col>
        <Col xs={12}>
          <Form onSubmit={handleSubmit(searchDebts)}>
            <TextInput
              control={control}
              label={t("label_rut")}
              name="rut"
              formatter="rut"
              rules={{
                required: t("rut_required"),
                validate: (value: string) => {
                  if (!validateRut(clean(value))) {
                    return t("invalid_rut");
                  } else {
                    return undefined;
                  }
                },
              }}
            />

            {!!(profile?.rut && validateRut(clean(profile?.rut))) && (
              <Button
                text={format(profile?.rut)}
                icon="search_document"
                className="w-100"
                outlined
                size="md"
                onClick={() => {
                  setValue("rut", profile?.rut);
                }}
              />
            )}

            <FormGroup className="pt-4 g-recaptcha-center">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={(token) => setValue("token", token ?? "")}
                hl={t("language_code")}
                size="normal"
              />
            </FormGroup>
            <div className="py-4">
              <CustomButton
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                text={t("search_debts")}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SearchStep;
