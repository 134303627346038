import { Col, Row } from "reactstrap";
import { ReactComponent as CheckCircleIcon } from "../../../../../assets/svg/icons/check-circle.svg";
import { t } from "../../../../../utils/utils";

export type BaseSuccessProps = {
  items?: { label: string; value: string }[];
};

export default function BaseSuccess({ items = [] }: BaseSuccessProps) {
  return (
    <div>
      <div className="container-max-400 mx-auto pb-4">
        <Row>
          <Col xs={12} className="text-center p-4">
            <CheckCircleIcon
              className="svg-secondary"
              width="60px"
              height="60px"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center fs-1-5 color-dark">
            {t("payment_success")}
          </Col>
        </Row>
      </div>
      <div className="mx-auto container-max-400 bg-color-body custom-rounded">
        <Row className="px-3 py-4">
          <Col xs={12} className="payment-info-container">
            <div className="table-responsive">
              <table className="table custom-table-info px-l mb-t">
                <tbody>
                  {items?.map((item, i) => (
                    <tr key={i.toString()}>
                      <th scope="row">{item.label}:</th>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
