import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { ReactComponent as User } from "../../assets/svg/icons/user.svg";
import { ReactComponent as Padlock } from "../../assets/svg/icons/padlock.svg";
import { ReactComponent as ChevronRight } from "../../assets/svg/icons/chevron-right.svg";
import ChangePassword from "./ChangePassword";
import { t } from "../../utils/utils";

const ProfileButton = (props: { name: string }) => {
  return (
    <>
      <div className="container-icon-user">
        <User width="2em" height="2em" />
      </div>
      <span className="pl-3 align-middle fs-1-1 color-dark text-capitalize text-decoration-none">
        {props.name}
      </span>
    </>
  );
};

type Props = {
  email?: string;
  name?: string;
  lastName?: string;
};

const ProfileMenu: React.FC<Props> = (props) => {
  const { name, lastName } = props;
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => {
    setIsProfileMenuOpen((isOpen) => !isOpen);
  };

  const [isChangePassowordOpen, setIsChangePassowordOpen] =
    React.useState(false);

  const openChangePasswordModal = () => {
    setIsChangePassowordOpen(true);
    setIsProfileMenuOpen(false);
  };
  const closeChangePasswordModal = () => {
    setIsChangePassowordOpen(false);
  };

  return (
    <div>
      {!name ? (
        <ProfileButton name={t("unaunthenticated_user")} />
      ) : (
        <>
          <Dropdown isOpen={isProfileMenuOpen} toggle={toggleProfileMenu}>
            <DropdownToggle color="link">
              <ProfileButton name={`${name || ""} ${lastName || ""}`} />
            </DropdownToggle>
            <DropdownMenu right>
              <div className="flex-column justify-content-start align-items-center p-3">
                <div className="d-flex justify-content-between align-items-center mx-2">
                  <span style={{ fontSize: "25px" }}>
                    <b>{t("account")}</b>
                  </span>
                  <span style={{ fontSize: "18px" }}>{props.email}</span>
                </div>
                <div className="d-flex w-100" style={{ marginTop: "30px" }}>
                  <button
                    className="custom-button change-password-btn"
                    onClick={openChangePasswordModal}
                  >
                    <div>
                      <Padlock
                        id="change-password-padlock"
                        className="my-3 mx-3"
                      />
                      <span style={{ fontSize: "18px" }}>
                        {t("change_password_button")}
                      </span>
                    </div>
                    <ChevronRight id="change-password-chevron" />
                  </button>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <ChangePassword
            isOpen={isChangePassowordOpen}
            onClose={closeChangePasswordModal}
          />
        </>
      )}
    </div>
  );
};

export default ProfileMenu;
