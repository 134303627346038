import { Button, Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

type PaymentSupplierItemProps = {
  code: string;
  active?: boolean;
  disabled?: boolean;
  onPress?: (code: string) => void;
};

const PaymentSupplierItem = ({
  code,
  active = false,
  disabled = false,
  onPress,
}: PaymentSupplierItemProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-100 d-flex align-items-center" style={{ gap: 8 }}>
      <div className="flex-fill">
        <Button
          text={t(`${code}-payWith`)}
          className="w-100"
          outlined
          disabled={disabled}
          type="button"
          onClick={() => !!onPress && onPress(code)}
        />
      </div>
      <div
        className={`border border-primary d-flex align-items-center justify-content-center p-1 ${
          active ? "bg-primary" : ""
        }`}
        style={{
          width: 20,
          height: 20,
          borderRadius: 12.5,
        }}
      >
        {!!active && <Icon name="check" color="white" size={16} />}
      </div>
    </div>
  );
};

export default PaymentSupplierItem;
