import { useTranslation } from "react-i18next";
import BaseError from "../Base/ErrorComponent";

type TransbankWPPErrorProps = {
  errorInUrl?: string;
};
const TransbankWPPError = ({ errorInUrl }: TransbankWPPErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-5">
      <BaseError
        title={t("payment_error")}
        instructions={t("payment_error_custom_message", {
          message: decodeURIComponent(atob(errorInUrl ?? "")),
        })}
        details={
          <>
            <p className="text-left">
              {t("webpay_error_rejected_details_desc")}
            </p>
            <ul className="text-left pl-3">
              <li>{t("webpay_error_rejected_details_1")}</li>
              <li>{t("webpay_error_rejected_details_2")}</li>
              <li>{t("webpay_error_rejected_details_3")}</li>
              <li>{t("webpay_error_rejected_details_4")}</li>
            </ul>
          </>
        }
      />
    </div>
  );
};

export default TransbankWPPError;
