import React from "react";

import ResultAlert from "../../../../../components/error/ResultAlert";

type BaseErrorProps = {
  title: string;
  instructions: string;
  details?: JSX.Element | null;
};
const BaseError = (props: BaseErrorProps) => {
  return <ResultAlert type="warning" {...props} />;
};

export default BaseError;
