import { useMemo } from "react";
import BaseSuccess, { BaseSuccessProps } from "../Base/SuccessComponent";
import { useTranslation } from "react-i18next";

export enum TransbankTransactionStatusEnum {
  Initilized = "INITIALIZED",
  Authorized = "AUTHORIZED",
  Reversed = "REVERSED",
  Failed = "FAILED",
  Nullified = "NULLIFIED",
  PartiallyNullified = "PARTIALLY_NULLIFIED",
  Captured = "CAPTURED",
}

type TransbankWPPSuccessProps = {
  data: {
    id: string;
    status: TransbankTransactionStatusEnum;
    integration: string;
    paymentTypeCode: string;
    amount: number;
    authorizationCode: string;
    installmentsNumber: number;
    installmentsAmount: number;
    transactionDate: string;
    vci: string;
    cardNumber: string;
    buyOrder: string;
    pdf: string | null;
  };
};

export default function TransbankWPPSuccess({
  data,
}: TransbankWPPSuccessProps) {
  const { t } = useTranslation();
  const items = useMemo(() => {
    if (!data?.id) {
      return [];
    }
    const elements: BaseSuccessProps["items"] = [];
    elements.push({
      label: t("buy_order"),
      value: data?.buyOrder,
    });
    elements.push({
      label: t("order_date"),
      value: data?.transactionDate,
    });
    elements.push({
      label: t("amount"),
      value: `$ ${data.amount}`,
    });
    elements.push({
      label: t("authorization_code"),
      value: data?.authorizationCode,
    });
    elements.push({
      label: t("transaction_date"),
      value: data?.transactionDate,
    });
    elements.push({
      label: t("payment_type"),
      value: data?.paymentTypeCode,
    });
    elements.push({
      label: t("installment_quantity"),
      value: data?.installmentsNumber.toString(),
    });
    elements.push({
      label: t("banking_card"),
      value: `**** **** **** ${data.cardNumber}`,
    });
    return elements;
  }, [t, data]);

  return <BaseSuccess items={items} />;
}
