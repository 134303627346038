import { AxiosResponse } from "axios";
import API from "../../../api/api";
import { handleError } from "../../../utils/utils";
import { RequestPayDebtsParams } from "../types";
import { PaymentSupplierCoreResponse } from "./parts/PaymentSupplierModal/PaymentSupplierRedirect";

const PAYMENT_STEP = {
  PAY: "/payment-flow/payment-step",
  SUPPLIERS: "/payment-flow/payment-step/suppliers",
};

const PAYMENT_STEP_AUTHENTICATED = {
  PAY: "/payment-flow/payment-step/authenticated",
  SUPPLIERS: "/payment-flow/payment-step/authenticated/suppliers",
  ONECLICK_INSCRIPTION: "/transbank-oneclick/inscription",
};

export const getAvailablePaymentSuppliers = async (
  isAuthenticated: boolean
): Promise<AxiosResponse<string[]>> => {
  try {
    const url = isAuthenticated
      ? PAYMENT_STEP_AUTHENTICATED.SUPPLIERS
      : PAYMENT_STEP.SUPPLIERS;
    return await API.get(url);
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const getAvailableOneclickCards = async (): Promise<
  AxiosResponse<{ token: string; cardType: string; cardNumber: string } | null>
> => {
  try {
    return API.get(PAYMENT_STEP_AUTHENTICATED.ONECLICK_INSCRIPTION);
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
export const removeAvailableOneclickCards = async (): Promise<
  AxiosResponse<{ token: string; cardType: string; cardNumber: string } | null>
> => {
  try {
    return API.delete(PAYMENT_STEP_AUTHENTICATED.ONECLICK_INSCRIPTION);
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const payDebtsRequest = async (
  body: RequestPayDebtsParams,
  isAuthenticated: boolean
): Promise<AxiosResponse<PaymentSupplierCoreResponse>> => {
  try {
    const url = isAuthenticated
      ? PAYMENT_STEP_AUTHENTICATED.PAY
      : PAYMENT_STEP.PAY;
    return await API.post(url, {
      ...body,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
