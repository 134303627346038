import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import CustomerInfo from "../../../components/paySteps/CustomerInfo";
import WebpayPostRedirect from "../../../components/paySteps/WebpayPostRedirect";
import { useUserState } from "../../../hooks";
import { t } from "../../../utils/utils";

import useUserStore from "../store/user/userStore";
import DataTable from "./parts/Table";

import { Button } from "@octano/global-ui";
import { format } from "rut.js";
import useStepStore from "../store/step/stepStore";
import AnonymousUserInputs from "./parts/AnonymousUserInputs";
import { PayerRut } from "./types";
import { usePaymentSuppliers } from "../hooks/usePaymentSuppliers";
import PaymentSupplierModal, {
  PaymentSupplierModalMethods,
} from "./parts/PaymentSupplierModal";

const PaymentStep = () => {
  const { user, debts } = useUserStore((state) => ({
    user: state.user,
    debts: state.debts,
  }));
  const prevStep = useStepStore((state) => state.prevStep);
  const { isLogged } = useUserState();
  const paymentSupplierRef = useRef<PaymentSupplierModalMethods>(null);

  const { suppliers: paymentSuppliers } = usePaymentSuppliers(isLogged);

  const [isPayLoading] = useState<boolean>(false);
  const [webpayRedirect] = useState<{
    token: string;
    initURL: string;
  }>();

  const { control, formState, watch } = useForm<PayerRut>({
    defaultValues: {
      payerRut: user?.customerIdentifier,
      payerEmail: "",
    },
    mode: "all",
  });

  const { payerRut, payerEmail } = watch();

  const selectedDebts = useMemo(
    () =>
      debts?.filter(
        (e) =>
          !!e.selected && e?.finalAmount?.value && e?.finalAmount?.value > 0
      ),
    [debts]
  );

  const disablePayment = useMemo(() => {
    if (!selectedDebts?.length || !formState.isValid) {
      return true;
    }

    if (!isLogged && (!payerEmail.length || !payerRut.length)) {
      return true;
    }
    return false;
  }, [selectedDebts, formState.isValid, payerEmail, payerRut, isLogged]);

  //si esta logeado solo deben haber dedudas seleccionadas y si no, el formulario debe estar valido
  // const disablePayment =||
  //   (!isLogged
  //     ? !formState.isValid || !payerEmail.length || !payerRut.length
  //     : false) ||
  //   !paymentSuppliers?.length;

  //si esta logeado solo deben haber dedudas seleccionadas y si no, el formulario debe estar valido
  // const disablePayment =||
  //   (!isLogged
  //     ? !formState.isValid || !payerEmail.length || !payerRut.length
  //     : false) ||
  //   !paymentSuppliers?.length;

  const handleIniTPayment = useCallback(() => {
    paymentSupplierRef?.current?.open({
      suppliers: paymentSuppliers,
      payerEmail,
      payerIdentifier: payerRut,
      debts: selectedDebts,
    });
  }, [paymentSuppliers, payerEmail, payerRut, selectedDebts]);

  return (
    <>
      <CustomerInfo rut={format(user?.customerIdentifier)} name={user?.name} />
      <p className="text-left h5 mx-5">{t("payment_step_description")}</p>
      <DataTable />
      <AnonymousUserInputs isLogged={isLogged} control={control} />
      <div className="container-max-400 ml-auto my-3 mr-5">
        <Row>
          <Col xs={6} className="pr-1 pr-sm-2">
            <Button
              type="button"
              text={t("cancel")}
              onClick={() => prevStep()}
              outlined
              fullwidth
            />
          </Col>
          <Col xs={6} className="pl-1 pl-sm-2">
            <Button
              type="button"
              text={t("pay")}
              disabled={disablePayment}
              onClick={handleIniTPayment}
              loading={isPayLoading}
              fullwidth
            />
          </Col>
          {webpayRedirect ? <WebpayPostRedirect {...webpayRedirect} /> : null}
        </Row>
      </div>

      <PaymentSupplierModal ref={paymentSupplierRef} isLoggedIn={isLogged} />
    </>
  );
};

export default PaymentStep;
