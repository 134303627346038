import { useEffect, useState } from "react";
import { getAvailablePaymentSuppliers } from "../paymentStep/api";

export function usePaymentSuppliers(isAuthenticated: boolean) {
  const [suppliers, setSuppliers] = useState<string[]>([]);

  useEffect(() => {
    getAvailablePaymentSuppliers(isAuthenticated)
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch(() => setSuppliers([]));
  }, [isAuthenticated]);

  return {
    suppliers,
  };
}
