import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from "@mui/x-data-grid";
import { Icon } from "@octano/global-ui";
import { REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY } from "../../../../../config/constants";
import currencyFormat, {
  currencyFormatNoSign,
} from "../../../../../utils/currency-format";
import {
  DebtSupplierCoreAmount,
  DebtSupplierCoreItem,
  DebtSupplierCoreStatus,
} from "../../../../../types/debtType";
import { Input, InputAdornment } from "@mui/material";

export default function useColumns(
  onChangeFinalAmount: (item: DebtSupplierCoreItem) => void
): GridColDef[] {
  return [
    {
      field: "id",
      headerName: "N Dcto",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.idToDisplay;
      },
    },
    {
      field: "type",
      headerName: "Tipo",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "details",
      headerName: "Detalle",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "installment",
      headerName: "N cuota",
      type: "number",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "installmentDueDate",
      headerName: "Fecha venc.",
      type: "string",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params: string) => {
        return new Date(params).toLocaleDateString();
      },
    },
    {
      field: "amount",
      headerName: "Monto Capital",
      type: "custom",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
      valueFormatter: (value: DebtSupplierCoreAmount) => {
        return currencyFormat(value.value);
      },
    },
    {
      field: "interests",
      headerName: "Interés",
      type: "custom",
      headerAlign: "center",
      align: "center",
      width: 100,
      valueFormatter: (value: DebtSupplierCoreAmount) => {
        return currencyFormat(value.value);
      },
    },
    {
      field: "finalAmount",
      headerName: "Total",
      type: "custom",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        if (!REACT_APP_PP_PAY_INSTALLMENTS_PARTIALLY) {
          if (!params.row.selected) {
            return "";
          }
          return currencyFormat(params.value.value);
        }
        return (
          <Input
            placeholder={currencyFormatNoSign(0, 2)}
            value={
              params.row.selected
                ? currencyFormatNoSign(params.value.value, 2)
                : currencyFormatNoSign(
                    params.row.amount.value + params.row.interests.value,
                    2
                  )
            }
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            disabled={!params.row.selected}
            onChange={(e) => {
              const maxAmount =
                params.row.amount.value + params.row.interests.value;
              const nextAmount =
                Number(e.target.value.replace(/\D/g, "")) / 100;
              onChangeFinalAmount({
                ...params.row,
                finalAmount: {
                  ...params.value,
                  value:
                    nextAmount <= maxAmount
                      ? Math.max(0, nextAmount)
                      : params.value.value,
                },
              });
            }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "En progreso",
      type: "custom",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        if (params.value === DebtSupplierCoreStatus.InProcess) {
          return <Icon name="circle" />;
        }
        return <></>;
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      minWidth: 150,
      flex: 1,
    },
    ...(process.env.NODE_ENV !== "production"
      ? ([
          {
            field: "selected",
            headerName: "Seleccionada",
            type: "boolean",
            headerAlign: "center",
            align: "center",
            width: 150,
          },
        ] as GridColDef[])
      : []),
  ];
}
