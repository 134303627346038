import { Button, Modal, addToast } from "@octano/global-ui";
import {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PaymentSupplierItems from "./PaymentSupplierItems";
import { payDebtsRequest } from "../../api";
import { DebtSupplierCoreItem } from "../../../../../types/debtType";
import PaymentSupplierRedirect, {
  PaymentSupplierRedirectMethods,
} from "./PaymentSupplierRedirect";
import OneclickPaymentSupplierModal, {
  OneclickPaymentSupplierModalMethods,
} from "../OneclickPaymentSupplierModal";

export type PaymentSupplierOptions = {
  suppliers: string[];
  payerIdentifier: string;
  payerEmail: string;
  debts: DebtSupplierCoreItem[];
};

export type PaymentSupplierModalMethods = {
  open: (options: PaymentSupplierOptions) => void;
  close: () => void;
};

type PaymentSupplierModalProps = {
  isLoggedIn?: boolean;
};

type FormFields = Omit<PaymentSupplierOptions, "suppliers"> & {
  supplier: string;
};

const keyPrefix = "gateway-modal-";

const PaymentSupplierModal = (
  { isLoggedIn = false }: PaymentSupplierModalProps,
  ref: Ref<PaymentSupplierModalMethods>
) => {
  const { t } = useTranslation();
  const paymentSupplierRedirectRef =
    useRef<PaymentSupplierRedirectMethods>(null);
  const oneclickPaymentSupplierRedirectRef =
    useRef<OneclickPaymentSupplierModalMethods>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<PaymentSupplierOptions>();

  const { reset, watch, setValue, handleSubmit } = useForm<FormFields>();

  const [selectedSupplier] = watch(["supplier"]);

  const close = useCallback(() => {
    if (isLoading) {
      return;
    }
    setVisible(false);
    setOptions(undefined);
    reset();
  }, [reset, isLoading]);

  const handleInit = useCallback(
    async (values: FormFields) => {
      try {
        if (isLoading) {
          return;
        }
        setIsLoading(true);
        const { data: response } = await payDebtsRequest(values, isLoggedIn);
        paymentSupplierRedirectRef?.current?.redirect(response);
      } catch (error) {
        setIsLoading(false);
        addToast({
          icon: "error",
          color: "danger",
          text: t("unexpected_error_msg"),
        });
      }
    },
    [isLoggedIn, t, isLoading]
  );

  const handleOpenOneclick = useCallback((values: FormFields) => {
    oneclickPaymentSupplierRedirectRef?.current?.open(values);
  }, []);

  const open = useCallback(
    (otp: PaymentSupplierOptions) => {
      if (!otp?.suppliers?.length) {
        return [];
      }
      setOptions(otp);
      reset({
        payerEmail: otp.payerEmail,
        payerIdentifier: otp.payerIdentifier,
        debts: otp.debts ?? [],
      });
      setVisible(true);
    },
    [reset]
  );

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <>
      <Modal isOpen={visible} toggle={close}>
        <div className="w-100">
          <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-2">
            {t(`${keyPrefix}title`)}
          </span>
          <span className="d-block w-100 text-light fs-16 lh-20 text-center mb-4">
            {t(`${keyPrefix}subtitle`)}
          </span>

          <PaymentSupplierItems
            value={selectedSupplier}
            onChange={(next) => setValue("supplier", next)}
            disabled={isLoading}
            options={options?.suppliers}
          />
          <div
            className="w-100 d-flex flex-wrap justify-content-center align-items-center mt-4"
            style={{ gap: 18 }}
          >
            <div className="flex-fill">
              <Button
                className="w-100 mt-3"
                onClick={close}
                type="button"
                text={t(`${keyPrefix}cancel`)}
                outlined
                disabled={isLoading}
              />
            </div>
            <div className="flex-fill">
              <Button
                className="w-100 mt-3"
                onClick={
                  selectedSupplier === "payment-supplier.transbank-oneclick"
                    ? handleSubmit(handleOpenOneclick)
                    : handleSubmit(handleInit)
                }
                type="button"
                text={t(`${keyPrefix}confirm`)}
                disabled={!selectedSupplier}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
      <PaymentSupplierRedirect ref={paymentSupplierRedirectRef} />
      <OneclickPaymentSupplierModal
        ref={oneclickPaymentSupplierRedirectRef}
        onConfirm={handleInit}
      />
    </>
  );
};

export default forwardRef(PaymentSupplierModal);
