import { Modal, TextInput, addToast } from "@octano/global-ui";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { requestChangePassword } from "../../api/request";
import { t } from "../../utils/utils";
import CustomButton from "../button/CustomButton";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type Inputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePassword(props: Props) {
  const {
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitting },
    control,
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const onChangePassword = async (inputs: Inputs) => {
    try {
      const res = await requestChangePassword(
        inputs.currentPassword,
        inputs.newPassword
      );
      if (res.status === 200) {
        props.onClose();
        addToast({
          icon: "check",
          color: "success",
          text: t("change_password_success"),
        });
      } else {
        throw new Error();
      }
    } catch (err: any) {
      if (err?.response?.data?.message === "Wrong current password") {
        addToast({
          icon: "error",
          color: "danger",
          text: t("change_password_error_current_password"),
        });
      } else {
        addToast({
          icon: "error",
          color: "danger",
          text: t("change_password_error"),
        });
      }
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onClose}
      onClosed={props.onClose}
      centered
    >
      <span className="py-2 px-4 px-sm-1 fs-1-5 color-dark d-flex justify-content-center">
        <p>Cambiar Contraseña</p>
      </span>

      <Form onSubmit={handleSubmit(onChangePassword)}>
        <div style={{ textAlign: "left" }}>
          <TextInput
            control={control}
            type="password"
            name="currentPassword"
            label={t("change_password_field_current_password")}
            rules={{
              required: t("required_field"),
              minLength: {
                value: 8,
                message: t("min_length_password"),
              },
            }}
          />
          <TextInput
            control={control}
            type="password"
            name="newPassword"
            label={t("change_password_field_new_password")}
            rules={{
              required: t("required_field"),
              minLength: {
                value: 8,
                message: t("min_length_password"),
              },
            }}
            tooltip={t("min_length_password")}
          />

          <TextInput
            control={control}
            type="password"
            label={t("change_password_field_confirm_passoword")}
            name="confirmPassword"
            rules={{
              required: t("required_field"),
              validate: (value) => {
                if (value !== getValues("newPassword")) {
                  return t("validate_repeat_password");
                }
                return undefined;
              },
              minLength: {
                value: 8,
                message: t("min_length_password"),
              },
            }}
            tooltip={t("min_length_password")}
          />

          <div className="mt-5">
            <CustomButton
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              text={t("confirm")}
            />
          </div>
          <div className="mt-3 mb-2">
            <CustomButton
              colorType="secondary"
              type="button"
              text={t("cancel")}
              onClick={props.onClose}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}
