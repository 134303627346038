import Box from "@mui/material/Box";
import { DataGrid, gridClasses, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useRef } from "react";
import {
  PP_BRAND_COLORS_PRIMARY,
  PP_BRAND_COLORS_SECONDARY,
} from "../../../../../config/constants";
import currencyFormat from "../../../../../utils/currency-format";
import useUserStore from "../../../store/user/userStore";
import useColumns from "./useColumns";
import {
  DebtSupplierCoreItem,
  DebtSupplierCoreStatus,
} from "../../../../../types/debtType";

export default function DataTable() {
  const apiRef = useGridApiRef();
  const didMount = useRef(false);
  const { debts, debtsPaid, checkDebts, updateDebt } = useUserStore(
    (state) => ({
      debts: state.debts,
      checkDebts: state.checkDebts,
      debtsPaid: state.debtsPaid,
      updateDebt: state.updateDebt,
    })
  );

  useEffect(() => {
    if (!didMount.current) {
      checkDebts([]);
    } else {
      didMount.current = true;
    }
  }, [checkDebts]);

  const columns = useColumns((params: DebtSupplierCoreItem) => {
    updateDebt(params.id, {
      finalAmount: params.finalAmount,
      id: params.id,
    });
  });

  return (
    <Box
      sx={{
        paddingLeft: 5,
        paddingRight: 5,
        width: "100%",
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-top": {
          backgroundColor: PP_BRAND_COLORS_SECONDARY,
          background: PP_BRAND_COLORS_SECONDARY,
          color: "white",
        },
        [`& .${gridClasses.checkboxInput}`]: {
          color: PP_BRAND_COLORS_PRIMARY,
        },
      }}
    >
      <DataGrid
        apiRef={apiRef}
        autoHeight
        hideFooterPagination
        rows={debts.map((debt) => ({
          ...debt,
          status: debtsPaid.some((d) => d.id === debt.id)
            ? DebtSupplierCoreStatus.InProcess
            : debt.status,
        }))}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onProcessRowUpdateError={(error) => {
          console.log(error);
        }}
        onRowSelectionModelChange={(rowSelectionModel) => {
          checkDebts(rowSelectionModel as DebtSupplierCoreItem["id"][]);
        }}
        isRowSelectable={(debt) => {
          return debt.row.status === DebtSupplierCoreStatus.Pending;
        }}
        slots={{
          footer: () => (
            <div
              style={{
                backgroundColor: PP_BRAND_COLORS_SECONDARY,
                height: 50,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                color: "white",
                fontSize: "1.25rem",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Total a pagar:{" "}
              {currencyFormat(
                debts.reduce(
                  (total, debt) =>
                    debt.selected
                      ? total + (debt.finalAmount.value || 0)
                      : total,
                  0
                )
              )}
            </div>
          ),
        }}
      />
    </Box>
  );
}
