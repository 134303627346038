import {
  Button,
  Dialog,
  Icon,
  Loading,
  Modal,
  showDialogConfirm,
} from "@octano/global-ui";
import {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  getAvailableOneclickCards,
  removeAvailableOneclickCards,
} from "../../api";

export type OneclickPaymentSupplierModalOpenProps = Record<string, any> & {
  additionalData?: any;
};

export type OneclickPaymentSupplierModalMethods = {
  open: (values: any) => void;
  close: () => void;
};

type OneclickPaymentSupplierModalProps = {
  onConfirm?: (values: any) => void;
};

const keyPrefix = "oneclick-modal-";

const OneclickPaymentSupplierModal = (
  { onConfirm }: OneclickPaymentSupplierModalProps,
  ref: Ref<OneclickPaymentSupplierModalMethods>
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [values, setValues] = useState<OneclickPaymentSupplierModalOpenProps>(
    {}
  );
  const [savedCard, setSavedCard] = useState<{
    token: string;
    cardType: string;
    cardNumber: string;
  } | null>(null);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const { data } = await getAvailableOneclickCards();
      setSavedCard(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      close();
    }
  }, [close, isLoading]);

  const confirm = useCallback(
    (inscription: string | null) => {
      !!onConfirm && onConfirm({ ...values, additionalData: { inscription } });
      close();
    },
    [onConfirm, close, values]
  );

  const open = useCallback(
    (v: OneclickPaymentSupplierModalOpenProps) => {
      setValues(v);
      fetchData();
      setVisible(true);
    },
    [fetchData]
  );

  const deleteCard = useCallback(async () => {
    if (!savedCard || isDeleting || isLoading) {
      return;
    }
    try {
      setIsDeleting(true);
      await removeAvailableOneclickCards();
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
    }
  }, [savedCard, isDeleting, isLoading]);

  const requestDeleteCard = useCallback(() => {
    if (!savedCard || isDeleting || isLoading) {
      return;
    }
    showDialogConfirm({
      title: t(`${keyPrefix}remove-title`),
      subtitle: t(`${keyPrefix}remove-subtitle`),
      icon: {
        name: "warning",
        color: "warning",
      },
      btnConfirm: {
        text: t(`${keyPrefix}remove-confirm`),
        onConfirm: () => {
          deleteCard();
        },
      },
      btnClose: {
        text: t(`${keyPrefix}remove-cancel`),
        onClose: () => {},
      },
    });
  }, [savedCard, isDeleting, isLoading, t, deleteCard]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <>
      <Modal isOpen={visible} toggle={close}>
        <div className="w-100">
          <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-2">
            {t(`${keyPrefix}title`)}
          </span>
          <span className="d-block w-100 text-light fs-16 lh-20 text-center mb-4">
            {t(`${keyPrefix}subtitle`)}
          </span>
          <div
            className="w-100 d-flex flex-wrap justify-content-center align-items-center mt-4"
            style={{ gap: 18 }}
          >
            {!isLoading && !isDeleting ? (
              <>
                {!!savedCard && (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{ gap: 8 }}
                  >
                    <div className="flex-fill">
                      <Button
                        className="w-100"
                        type="button"
                        onClick={() => confirm(savedCard.token)}
                        text={t(`${keyPrefix}card`, {
                          brand: savedCard.cardType,
                          last4: savedCard.cardNumber,
                        })}
                        outlined
                        loading={isLoading}
                      />
                    </div>
                    <button
                      type="button"
                      className="border border-danger p-4 rounded btn"
                      onClick={requestDeleteCard}
                    >
                      <Icon name="trash" color="danger" />
                    </button>
                  </div>
                )}
                <Button
                  className="w-100"
                  type="button"
                  onClick={() => confirm(null)}
                  text={t(`${keyPrefix}new`)}
                  icon="plus"
                  size={savedCard ? "md" : "lg"}
                  outlined
                  loading={isLoading}
                />
                <Button
                  className="w-100"
                  onClick={close}
                  type="button"
                  text={t(`${keyPrefix}cancel`)}
                  outlined
                  icon="close"
                  size="md"
                  color="danger"
                  disabled={isLoading}
                />
              </>
            ) : (
              <Loading textBody={t(`${keyPrefix}loading`)} />
            )}
          </div>
        </div>
      </Modal>
      <Dialog />
    </>
  );
};

export default forwardRef(OneclickPaymentSupplierModal);
